import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Register from 'views/register'
import Login from 'views/login'
import Market from 'views/market'
import Landing from 'views/landing'

const routes = [
  {
    path: '/',
    Component: Landing,
  },
  {
    path: '/market',
    Component: Market,
  },
  {
    path: '/register',
    Component: Register,
  },
  {
    path: '/login',
    Component: Login,
  },
]

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component }) => (
        <Route key={path} element={<Component />} path={path} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
