import React from 'react'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Image,
  Input,
  Link,
  Text,
} from '@chakra-ui/react'
import './footer.scss'
import { socialLinks, footerLinks } from 'utils/const'
import { FooterLink } from 'utils/types/global'

export default function Footer() {
  return (
    <Box
      bg="white"
      as="footer"
      pt="45px"
      mt="90px"
      boxShadow="0px -2px 10px rgba(147, 78, 206, 0.1)"
    >
      <Container maxW="1280px">
        <div className="footer-banner">
          <Text className="fs--30 fw--700">
            Register to Claim Bonuses of up to 5125 USDT
          </Text>
          <Text className="fs--20 fw--400" p="0 0.5rem 1.5rem">
            It only takes a minute.
          </Text>
          <Flex wrap="wrap" align="center" justify="center" gap="12px">
            <Input
              placeholder="Please enter your phone number/email"
              w="493px"
              padding="1.5rem 1rem"
              _placeholder={{ color: 'white' }}
            />

            <Button
              bg="white"
              color="primary.400"
              border="1px solid Transparent"
              _hover={{
                color: 'white',
                bg: 'transparent',
                border: '1px solid white',
              }}
              size="lg"
            >
              Register Now
            </Button>
          </Flex>
        </div>
        <Flex wrap="wrap" mt="2.2rem" pb="1.5rem">
          <Box flex="0.3">
            <Heading className="fs--40 fw--700" color="#33263E">
              LOGO
            </Heading>
            <Text className="fs--12" color="#52525B" p="1rem 3rem 1rem 0">
              Lorem ipsum dolor sit amet consectetur. Aliquet congue amet donec
              eu. Pellentesque sodales id.
            </Text>
            <Flex gap="7px">
              {socialLinks.map(({ url, icon }) => (
                <Box
                  as={Link}
                  href={url}
                  isExternal
                  key={url}
                  className={`socialLink `}
                >
                  <Image src={icon} />
                </Box>
              ))}
            </Flex>
          </Box>
          {Object.entries(footerLinks).map(item => (
            <FooterLinks key={item[0]} item={item} />
          ))}
        </Flex>
      </Container>
      <Divider />
      <Text
        textAlign="center"
        color="#71717A"
        fontSize="12.6"
        fontWeight="400"
        py="1.5rem"
      >
        © Copyright {new Date().getFullYear()}, All Rights Reserved by Extsy
      </Text>
    </Box>
  )
}

type Props = {
  item: [string, FooterLink[]]
}

function FooterLinks({ item }: Props) {
  return (
    <Box flex="0.17">
      <Text textTransform="capitalize" className="footer-heading">
        {item[0]?.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}
      </Text>
      {item[1].map(({ label, url }) => (
        <Link href={url} isExternal className="footer-link" key={label}>
          {label}
        </Link>
      ))}
    </Box>
  )
}
