import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import language from 'assets/icons/header/language.svg'
import fileDownload from 'assets/icons/header/file_download.svg'
import logo from 'assets/images/logo.svg'
import { Image } from '@chakra-ui/image'
import { Button, ButtonGroup } from '@chakra-ui/button'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link as ExternalLink,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { menu } from 'utils/const'
import './header.scss'
import { Link } from 'react-router-dom'
import { useIsLogin } from '../../hooks/index'

const { REACT_APP_SOCIAL_MEDIA_APP } = process.env

export default function Header() {
  const isLogin = useIsLogin()
  return (
    <Flex
      bg="white"
      as="header"
      p="1.3rem"
      alignItems="center"
      boxShadow=" 0px 0px 15px 0px #0000001A"
    >
      <Link to="/">
        <Image src={logo} />
      </Link>
      <Flex as="nav" flex="1" ml={'7rem'} gap="2" align="center">
        {menu.map(({ label, subMenu, path }) =>
          subMenu ? (
            <Menu key={label}>
              <MenuButton
                fontSize="14px"
                fontWeight="300"
                variant="ghost"
                color="none"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {label}
              </MenuButton>
              <MenuList>
                {subMenu?.map(({ label, path }) => (
                  <MenuItem as={Link} to={path} key={label}>
                    {label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <Text
              as={Link}
              to={path}
              key={label}
              fontSize="14px"
              fontWeight="300"
              mr="1rem"
            >
              {label}
            </Text>
          ),
        )}

        <ExternalLink
          href={REACT_APP_SOCIAL_MEDIA_APP}
          fontSize="14px"
          fontWeight="300"
          mr="1rem"
        >
          Socialize
        </ExternalLink>
      </Flex>
      {!isLogin && (
        <ButtonGroup gap="2" pr="2rem">
          <Button
            to="/login"
            as={Link}
            fontSize="14px"
            fontWeight="300"
            variant="ghost"
          >
            Login
          </Button>
          <Button
            to="/register"
            as={Link}
            fontSize="12px"
            fontWeight="400"
            width="106px"
            height="35px"
          >
            Register
          </Button>
          <Image src={fileDownload} />
          <Image src={language} />
        </ButtonGroup>
      )}
    </Flex>
  )
}
