import React from 'react'
import { VStack, Box, Text, Flex, Image } from '@chakra-ui/react'
import Polygon from 'assets/images/bnb-coin.png'
import Graph from 'components/graph'

export default function MarketCoinStats() {
  return (
    <Box
      bg="white"
      py={17}
      px={18}
      flex="0.3"
      border="1px"
      borderColor="#EBEEEF"
    >
      <VStack>
        <Text alignSelf="start" fontWeight="500" color="heading">
          Top gainer (24h)
        </Text>
        <Flex alignItems="center" w="100%">
          <Flex w="50%" direction="row">
            <Image mt={1} w="32px" h="32px" src={Polygon} alt="Dan Abramov" />
            <Flex direction="column">
              <Text color="heading" fontSize={16} pl={4}>
                Polygon
              </Text>
              <Text color="success" fontSize={14} pl={4}>
                +30.33%
              </Text>
            </Flex>
          </Flex>
          <Flex
            w="50%"
            position="relative"
            direction="column"
            px={0}
            alignItems="end"
          >
            <Text pb="40px" color="heading" fontSize={16} pl={4}>
              TRY 17.91
            </Text>
            <Box position="absolute" right="-8px" top="-2px">
              <Graph
                height={100}
                width={170}
                data={[10, 55, 45, 37, 78, 60]}
                categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June']}
              />
            </Box>
          </Flex>
        </Flex>
      </VStack>
    </Box>
  )
}
