import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { Text, Flex, ButtonGroup, Button, Box, Image } from '@chakra-ui/react'
import Graph from 'components/graph'

type currencyUnitValue = {
  currencyBtcValue: number
  currencyUSDTvalue: number
}
interface UnitConversion {
  currencyName: string
  coinImage: string
  currencyPrice: currencyUnitValue
  currencyStatus: number
  currencyHighValue: string
  currencyLowValue: string
  currencyMarket: string
  currencyAction: string[]
}
export const data: UnitConversion[] = [
  {
    currencyName: 'BTC/USDT',
    coinImage:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Fbitcoin%2Fcolor_icon.png&w=32&q=75',
    currencyPrice: {
      currencyBtcValue: 23819.0,
      currencyUSDTvalue: 167222.61,
    },
    currencyStatus: 1.89,
    currencyHighValue: '23,819.00',
    currencyLowValue: '19,769.00',
    currencyMarket: '$457.07B',
    currencyAction: ['Details', 'Trade'],
  },
  {
    currencyName: 'ETH/USDT',
    coinImage:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Fethereum%2Fcolor_icon.png&w=32&q=75',
    currencyPrice: {
      currencyBtcValue: 1644.9,
      currencyUSDTvalue: 167222.61,
    },
    currencyStatus: 2.08,
    currencyHighValue: '16,769.00',
    currencyLowValue: '15,769.00',
    currencyMarket: '$199.09B',
    currencyAction: ['Details', 'Trade'],
  },
  {
    currencyName: 'BNB/USDT',
    coinImage:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Fbnb%2Fcolor_icon.png&w=32&q=75',
    currencyPrice: {
      currencyBtcValue: 317.0,
      currencyUSDTvalue: 167222.61,
    },
    currencyStatus: 1.09,
    currencyHighValue: '369.00',
    currencyLowValue: '179.00',
    currencyMarket: '$49.37B',
    currencyAction: ['Details', 'Trade'],
  },
  {
    currencyName: 'MATIC/USDT',
    coinImage:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Fpolygon%2Fcolor_icon.png&w=32&q=75',
    currencyPrice: {
      currencyBtcValue: 1.17,
      currencyUSDTvalue: 167222.61,
    },
    currencyStatus: -0.26,
    currencyHighValue: '1.70',
    currencyLowValue: '1.00',
    currencyMarket: '$10.07B',
    currencyAction: ['Details', 'Trade'],
  },
  {
    currencyName: 'TETH/USDT',
    coinImage:
      'https://crypto.com/price/_next/image?url=https%3A%2F%2Fstatic.crypto.com%2Ftoken%2Ficons%2Ftether%2Fcolor_icon.png&w=32&q=75',
    currencyPrice: {
      currencyBtcValue: 1.02,
      currencyUSDTvalue: 167222.61,
    },
    currencyStatus: -0.01,
    currencyHighValue: '1.80',
    currencyLowValue: '0.98',
    currencyMarket: '$67.78B',
    currencyAction: ['Details', 'Trade'],
  },
  // {
  //   currencyName: 'BTC/USDT',
  //   currencyPrice: {
  //     currencyBtcValue: 40786.0,
  //     currencyUSDTvalue: 167222.61,
  //   },
  //   currencyStatus: -0.39,
  //   currencyHighValue: '16,769.00',
  //   currencyLowValue: '16,769.00',
  //   currencyMarket: '$319.75B',
  //   currencyAction: ['Details', 'Trade'],
  // },
  // {
  //   currencyName: 'BTC/USDT',
  //   currencyPrice: {
  //     currencyBtcValue: 40786.0,
  //     currencyUSDTvalue: 167222.61,
  //   },
  //   currencyStatus: -0.39,
  //   currencyHighValue: '16,769.00',
  //   currencyLowValue: '16,769.00',
  //   currencyMarket: '$319.75B',
  //   currencyAction: ['Details', 'Trade'],
  // },
]
const columnHelper = createColumnHelper<UnitConversion>()
export const columns = [
  columnHelper.accessor('currencyName', {
    cell: info => info.getValue(),
    header: 'Trading Pair',
  }),
  columnHelper.accessor('currencyPrice', {
    cell: info => (
      <Flex direction="column">
        <Text fontSize={16}>{info.getValue().currencyBtcValue}</Text>
        <Text fontSize={14} color="muted">
          {info.getValue().currencyUSDTvalue} USDT
        </Text>
      </Flex>
    ),

    header: 'Last Price(USDT)',
  }),
  columnHelper.accessor('currencyStatus', {
    cell: info => (
      <Flex>
        {info.getValue() >= 0 ? (
          <Text color="success">{`+${info.getValue()}%`}</Text>
        ) : (
          <Text color="error">{`${info.getValue()}%`}</Text>
        )}
      </Flex>
    ),
    header: '24h Change',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('currencyHighValue', {
    cell: info => info.getValue(),
    header: '24h High',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('currencyLowValue', {
    cell: info => info.getValue(),
    header: '24h Low',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('currencyMarket', {
    cell: info => info.getValue(),
    header: 'Market Cap',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('currencyAction', {
    cell: info => (
      <ButtonGroup spacing="6">
        {info.getValue().map(item => {
          return (
            <Button fontSize={12} key={item} variant="link">
              {item.toString()}{' '}
            </Button>
          )
        })}
      </ButtonGroup>
    ),
    header: 'Action',
    meta: {
      isNumeric: true,
    },
  }),
]

export const columns2 = [
  columnHelper.accessor('currencyName', {
    cell: info => (
      <Flex alignItems="center">
        <Image w="30px" src={info?.row?.original?.coinImage} />
        <Text ml="20px">{info?.row?.original?.currencyName}</Text>
      </Flex>
    ),
    header: 'Coin',
  }),
  columnHelper.accessor('currencyPrice', {
    cell: info => (
      <Flex direction="column">
        <Text fontSize={16}>{info.getValue().currencyBtcValue}</Text>
        <Text fontSize={14} color="muted">
          {info.getValue().currencyUSDTvalue} USDT
        </Text>
      </Flex>
    ),

    header: 'Last Price',
  }),
  columnHelper.accessor('currencyStatus', {
    cell: info => (
      <Flex>
        {info.getValue() >= 0 ? (
          <Text color="success">{`+${info.getValue()}%`}</Text>
        ) : (
          <Text color="error">{`${info.getValue()}%`}</Text>
        )}
      </Flex>
    ),
    header: '24h Change',
  }),
  columnHelper.accessor('currencyStatus', {
    cell: info => (
      <Box position="absolute" top="-24px" left="0">
        <Graph
          colors={info.getValue() >= 0 ? ['#4ECA78'] : ['#E84A4A']}
          data={[30, 31, 10, 42, 104, 53, 52, 90, 60, 68, 60, 20, 90]}
          width="180px"
          height="100px"
          categories={[
            '30',
            '31',
            '40',
            '42',
            '43',
            '53',
            '52',
            '60',
            '62',
            '68',
            '60',
            '89',
            '90',
          ]}
        />
      </Box>
    ),
    header: 'Market',
  }),
  columnHelper.accessor('currencyAction', {
    cell: info => (
      <ButtonGroup spacing="6">
        {info.getValue().map(item => {
          return (
            <Button fontSize={12} key={item} variant="link">
              {item.toString()}{' '}
            </Button>
          )
        })}
      </ButtonGroup>
    ),
    header: '',
  }),
]
