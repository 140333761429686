import UserAuth from 'components/userAuth'
import React from 'react'

export default function Register() {
  return (
    <div>
      <UserAuth
        isLoading={false}
        title="Register"
        onSubmitData={() => console.log('register')}
        isRegister
      />
    </div>
  )
}
