import UserAuth from 'components/userAuth'
import React, { useEffect } from 'react'
import { useLogin } from '../../utils/apis/auth.api'
// import { useNavigate } from 'react-router-dom';

export default function Login() {
  const { REACT_APP_SOCIAL_MEDIA_APP } = process.env
  const { mutate, isSuccess, data: response, isLoading } = useLogin()
  const handleLogin = (data: unknown) => mutate(data)
  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('access_token', response?.token)
      window.location.assign(
        `${REACT_APP_SOCIAL_MEDIA_APP}/landing?access_token=${response?.token}`,
      )
    }
  }, [isSuccess])
  return (
    <UserAuth
      title="Login"
      isLogin
      isLoading={isLoading}
      onSubmitData={handleLogin}
    />
  )
}
