import React from 'react'
import Footer from 'components/footer'
import Header from 'components/header'
import { BrowserRouter } from 'react-router-dom'
import './app.scss'
import Routes from 'routes'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes />
      <Footer />
    </BrowserRouter>
  )
}

export default App
