import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputLeftAddon,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { InputGroup, useDisclosure, useOutsideClick } from '@chakra-ui/react'
import { AsYouType } from 'libphonenumber-js'
import { ChevronDownIcon, ChevronUpIcon, PhoneIcon } from '@chakra-ui/icons'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { AuthFormValues } from 'utils/types/global'
// import Countries from 'country-list'

export type PhoneNumberInputProps = {
  // value: string;
  onChange: (arg: string) => void
  errors: FieldErrors<AuthFormValues>
  register: UseFormRegister<AuthFormValues>
}

export type Country = {
  flags: {
    png: string
    svg: string
  }
  idd: {
    root: string
    suffixes: string[]
  }
  code: string
  name: {
    common: string
  }
}

export type Props = {
  data: Country[]
  onChange: (args: Country) => void
  selectedItem: Country | undefined
}

export const PhoneNumberInput = ({
  onChange,
  errors,
  register,
}: PhoneNumberInputProps) => {
  const ref = useRef(null)
  const [country, setCountry] = useState<string | undefined>('+1')
  const [countryList, setCountryList] = useState([])
  const [countryFlag, setCountryFlag] = useState<string | undefined>(
    'https://flagcdn.com/w320/us.png',
  )
  const [selectedItem, setSelectedItem] = useState<Country | undefined>(
    undefined,
  )

  const { isOpen, onToggle, onClose } = useDisclosure()

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(res => res.json())
      .then(res => setCountryList(res))
  }, [])

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  useEffect(() => {
    if (country) {
      onChange(`${country}`)
    }
  }, [country, onChange])

  const onCountryChange = (item: Country) => {
    const parsedNumber = new AsYouType().input(`${country}`)
    const suffix = item?.idd?.suffixes.map(number => number)
    setSelectedItem(item)
    setCountry(item?.idd?.root + suffix)
    setCountryFlag(item?.flags['png'])
    onChange(parsedNumber)
    onClose()
  }

  return (
    <>
      <FormControl
        isInvalid={Boolean(errors.number)}
        mb="25px"
        as="section"
        ref={ref}
        position="relative"
      >
        <FormLabel htmlFor="name">Phone</FormLabel>
        <InputGroup>
          <InputLeftAddon
            bg="transparent"
            cursor="pointer"
            pl="0.5em"
            pr="0.2em"
            onClick={onToggle}
          >
            {countryFlag ? (
              <>
                <Image w="35px" mr="0.5em" src={countryFlag} />
                {country}
              </>
            ) : (
              <PhoneIcon color="gray.400" />
            )}
            {isOpen ? (
              <ChevronUpIcon boxSize={6} color="gray.500" />
            ) : (
              <ChevronDownIcon boxSize={6} color="gray.500" />
            )}
          </InputLeftAddon>
          <Input
            type="tel"
            focusBorderColor="primary.500"
            placeholder="Enter your number"
            {...register('number', {
              required: 'This is required',
            })}
          />
        </InputGroup>
        <>
          <FormErrorMessage>
            {errors.number && errors.number.message}
          </FormErrorMessage>
        </>
        {isOpen ? (
          <SearchOnList
            data={countryList}
            onChange={onCountryChange}
            selectedItem={selectedItem}
          />
        ) : null}
      </FormControl>
    </>
  )
}

const SearchOnList = ({ data, onChange, selectedItem }: Props) => {
  const [filteredList, setFilteredList] = useState(data)

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    const result: Country[] =
      data?.filter(item => {
        return item.name.common.toLowerCase().includes(value)
      }) || []
    setFilteredList(result)
  }
  return (
    <Box
      my={1}
      maxH="xs"
      bg="white"
      width="full"
      zIndex={999}
      height="auto"
      overflow="auto"
      borderRadius="lg"
      position="absolute"
      boxShadow="0px 1px 30px rgba(0, 0, 0, 0.1)"
    >
      <Box position="sticky" top="0" padding={4} bg="white">
        <Input
          size="sm"
          type="search"
          borderRadius="md"
          autoComplete="off"
          placeholder="Enter country name"
          onChange={event => handleSearch(event)}
          _focusWithin={{ borderColor: 'secondary' }}
          _invalid={{ bg: 'white', borderColor: 'gray.50' }}
        />
      </Box>

      <List>
        {filteredList?.map((item: Country, index: number) => (
          <ListItem
            px="20px"
            key={index}
            paddingY={2}
            cursor="pointer"
            textTransform="capitalize"
            onClick={() => {
              console.log('item', item, selectedItem)
              onChange(item)
            }}
            style={{
              transition: 'all .125s ease',
            }}
            _hover={{ bg: 'primary.100' }}
            sx={
              item?.name?.common === selectedItem?.name?.common
                ? { bg: 'primary.100' }
                : {}
            }
          >
            <Image display="inline-block" w="30px" src={item?.flags['png']} />
            <Text ml="25px" as="span">
              {item?.name?.common}
            </Text>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
