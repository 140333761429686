export default {
  fonts: {
    body: 'HarmonyOS Sans',
    heading: 'HarmonyOS Heading',
    label: 'HarmonyOS Sans',
  },
  colors: {
    primary: {
      main: '#924ece',
      50: '#f0e7f8',
      100: '#d8c3ed',
      200: '#bf9be2',
      300: '#a670d7',
      400: '#924ece',
      500: '#7f29c4',
      600: '#7523be',
      700: '#6819b6',
      800: '#5c10af',
      900: '#4900a1',
    },
    secondary: '#E7CEFC',
    text: '#0F001C',
    background: '#E4E4E4',
    error: '#E84A4A',
    success: '#4ECA78',
    muted: '#7B7382',
    heading: '#33263E',
    ChangeFilter: '#F8F8F9',
    secondaryText: '#934ECE',
  },
  components: {
    Text: {
      color: '#52525B',
    },
  },
  styles: {
    global: {
      body: {
        bg: '#FAFAFA',
      },
    },
  },
}
