import React, { useState } from 'react'
import {
  Container,
  Grid,
  Heading,
  Box,
  Input,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Button,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Checkbox,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Image,
} from '@chakra-ui/react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TfiEmail } from 'react-icons/tfi'
import { FiLock } from 'react-icons/fi'
import { BiHide, BiShow } from 'react-icons/bi'
import { PhoneNumberInput } from 'components/numberInput'
import { AuthFormValues } from 'utils/types/global'
import { Link } from 'react-router-dom'
import QRCode from 'assets/images/qr-code.png'

interface Props {
  title: string
  isLogin?: boolean
  isRegister?: boolean
  isLoading: boolean
  onSubmitData: (data: unknown) => void
}

export default function UserAuth({
  title,
  isRegister,
  isLoading,
  onSubmitData,
}: Props) {
  return (
    <Container maxW="1280px" pt="90px">
      <Heading>{title}</Heading>
      <Grid gridTemplateColumns="1fr 1fr" alignItems="center">
        <Box maxW="420px" mt="35px">
          <Tabs isLazy>
            <TabList border="none">
              <Tab>Email</Tab>
              <Tab>Number</Tab>
            </TabList>
            <TabPanels mt="35px">
              <TabPanel p="0">
                <HookForm
                  isLoading={isLoading}
                  isRegister={isRegister}
                  onSubmitData={onSubmitData}
                />
              </TabPanel>
              <TabPanel p="0">
                <HookForm
                  isLoading={isLoading}
                  isPhone
                  isRegister={isRegister}
                  onSubmitData={onSubmitData}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box textAlign="center">
          <Image mx="auto" width="221" height="221" src={QRCode} />
          <Heading my="15px" className="fs--30 fw--700" as="h2">
            Log in with QR code
          </Heading>
          <Text fontSize="14px">
            Scan this code with the{' '}
            <Button className="fs--14 fw--400" as="a" href="#" variant="link">
              EXTSY mobile app
            </Button>{' '}
            to log in instantly.
          </Text>
        </Box>
      </Grid>
    </Container>
  )
}

function HookForm({
  isLoading,
  isPhone,
  isRegister,
  onSubmitData,
}: {
  isLoading: boolean
  isPhone?: boolean
  isRegister?: boolean
  onSubmitData: (data: unknown) => void
}) {
  const [show, setShow] = useState(false)
  const handleClick = () => setShow(!show)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<AuthFormValues>()

  const onSubmit: SubmitHandler<AuthFormValues> = values => {
    return new Promise(resolve => {
      resolve(onSubmitData(values))
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isPhone ? (
        <PhoneNumberInput
          register={register}
          errors={errors}
          onChange={(e: string) => setValue('code', e)}
        />
      ) : (
        <FormControl isInvalid={Boolean(errors.email)} mb="25px">
          <FormLabel htmlFor="name">Email</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              // eslint-disable-next-line react/no-children-prop
              children={<Icon as={TfiEmail} color="gray.400" />}
            />
            <Input
              bg="white"
              _autofill={{ bg: 'none' }}
              focusBorderColor="primary.500"
              placeholder="john@mail.com"
              {...register('email', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
      )}

      <FormControl isInvalid={Boolean(errors.password)} mb="25px">
        <FormLabel htmlFor="password">Password</FormLabel>
        <InputGroup size="md">
          <InputLeftElement
            pointerEvents="none"
            // eslint-disable-next-line react/no-children-prop
            children={<Icon as={FiLock} color="gray.400" />}
          />
          <Input
            bg="white"
            focusBorderColor="primary.500"
            {...register('password', {
              required: 'This is required',
              minLength: { value: 8, message: 'Minimum length should be 8' },
            })}
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder="Password"
            autoComplete="new-password"
          />
          <InputRightElement
            // eslint-disable-next-line react/no-children-prop
            children={
              <Icon
                as={show ? BiShow : BiHide}
                color="gray.400"
                onClick={handleClick}
              />
            }
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.password && errors.password.message}
        </FormErrorMessage>
      </FormControl>
      {isRegister ? (
        <>
          <FormControl isInvalid={Boolean(errors.referral)} mb="25px">
            <FormLabel htmlFor="password">Referral code (Optional)</FormLabel>
            <Input
              bg="white"
              _autofill={{ bg: 'none' }}
              focusBorderColor="primary.500"
              {...register('referral')}
              placeholder="QXYWZ"
            />
            <FormErrorMessage>
              {errors.referral && errors.referral.message}
            </FormErrorMessage>
          </FormControl>
          <Checkbox defaultChecked>
            <Text fontSize="12px">
              I have read and agree to the{' '}
              <Button variant="link" className="fs--12 fw--400">
                Customer Agreement
              </Button>{' '}
              and{' '}
              <Button variant="link" className="fs--12 fw--400">
                Privacy Policy
              </Button>
              .
            </Text>
          </Checkbox>
        </>
      ) : (
        <Flex justifyContent="space-between">
          <Checkbox defaultChecked>
            <Text fontSize="14px" color="muted">
              Remember me
            </Text>
          </Checkbox>
          <Button
            to="/login"
            as={Link}
            variant="link"
            className="fs--14 fw--400"
          >
            Forgot password?
          </Button>
        </Flex>
      )}
      <Button
        fontWeight="400"
        w="100%"
        my="35px"
        isLoading={isLoading}
        type="submit"
      >
        {isRegister ? 'Register' : 'Login'}
      </Button>
      <Text color="muted" textAlign="center" className="fs--14 fw--400">
        {isRegister ? 'Existing Account?' : 'No Account?'}{' '}
        <Button
          to={isRegister ? '/login' : '/register'}
          as={Link}
          variant="link"
          className="fs--14 fw--400"
        >
          {isRegister ? 'Login' : 'Register'}
        </Button>
      </Text>
    </form>
  )
}
