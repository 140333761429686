import React from 'react'
import {
  Box,
  Container,
  Flex,
  FormControl,
  Heading,
  HStack,
  Text,
  Input,
  Button,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Card,
  CardHeader,
  CardBody,
  Avatar,
} from '@chakra-ui/react'
import Banner from 'assets/images/banner.png'
import Mobiles from 'assets/images/mobiles.png'
import DataTable from 'components/table'
import { columns2, data } from 'utils/MarketData'
import { BsArrowRight } from 'react-icons/bs'
import Certificate from 'assets/images/certificate.png'
import MobileApp from 'assets/images/mobile-app.png'
import QRCode from 'assets/images/qr-code.png'
import Sponsor from 'assets/images/sponsor-1.png'
import Spot from 'assets/images/spot.png'
import nft from 'assets/images/nft.png'
import p2p from 'assets/images/p2p.png'
import copy from 'assets/images/copy.png'
import features from 'assets/images/features.png'
import crypto from 'assets/images/crypto.png'
import BgImage from 'assets/images/home-bg.png'
import account from 'assets/images/account.png'
import trade from 'assets/images/trade.png'
import { IoLogoGooglePlaystore, IoLogoApple } from 'react-icons/io5'
import { IconType } from 'react-icons'
import Graph from 'components/graph'

export default function Landing() {
  return (
    <>
      <Box bgImage={`url(${BgImage})`} bgRepeat="no-repeat" bgSize="100% 78%">
        <Container maxW="1280px">
          <Flex as="section" pt="100px">
            <Box flex="1">
              <Heading as="h1" fontWeight="900" fontSize="72px">
                A Leading Crypto Exchange
              </Heading>
              <HStack my="60px">
                <Stats label="Global User" value="+5M" />
                <Stats label="24h Volume" value="+5M" />
                <Stats label="Listed Cryptocurrencies" value="+5M" />
              </HStack>
              <Flex as="form" maxW="490px" gap="12px">
                <FormControl display="inline-flex">
                  <Input
                    size="14px"
                    type="email"
                    padding="20px 30px"
                    border="1px solid #E7E5E8"
                    rounded="6px"
                    placeholder="Please enter your phone number/email"
                  />
                </FormControl>
                <Button
                  padding="20px 30px"
                  fontWeight="400"
                  fontSize="16px"
                  size="14px"
                  type="submit"
                >
                  Register
                </Button>
              </Flex>
            </Box>
            <Box flex="1">
              <Image src={Banner} />
            </Box>
          </Flex>
          <Box as="section" py="100px">
            <Flex mb="30px">
              <Heading as="h2" fontSize="28">
                Market
              </Heading>
            </Flex>
            <Box border="1px solid #EBEEEF" bg="white" rounded="10px">
              <Tabs w="100%">
                <TabList pt="20px">
                  <Tab px="2.5rem">Hot Listed</Tab>
                  <Tab px="2rem">Futures</Tab>
                  <Tab px="2rem">New Listing</Tab>
                  <Tab px="2rem">All Currencies</Tab>
                </TabList>
                <TabPanels>
                  {[1, 2, 3, 4].map(item => (
                    <TabPanel key={item}>
                      <DataTable columns={columns2} data={data} />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxW="1280px">
        <Flex as="section" pb="100px">
          <Box flex="1">
            <Heading as="h2" fontSize="40px">
              Let’s find more that brings us together.
            </Heading>
            <Text color="muted" my="23px">
              EXTSY helps you connect with friends, family and communities of
              people who share your interests. Connecting with your friends and
              family as well as discovering new ones is easy with features like
              Groups, Watch and Marketplace.
            </Text>
            <Button
              fontWeight="400"
              color="muted"
              _hover={{ bg: 'transparent' }}
              maxW="300px"
              w="100%"
              justifyContent="space-between"
              size="lg"
              variant="outline"
            >
              Socialize Now <BsArrowRight />
            </Button>
          </Box>
          <Box flex="1">
            <Image src={Mobiles} />
          </Box>
        </Flex>
        <Flex as="section">
          <Box flex="1">
            <TradersCard />
          </Box>
          <Box flex="1">
            <Heading as="h2" fontSize="40px">
              Join the Largest Crypto Trading Community
            </Heading>
            <Text color="muted" my="23px">
              Copy elite traders and auto-replicate their transactions in real
              time
            </Text>
            <HStack my="60px">
              <Stats label="Global User" value="+5M" />
              <Stats label="24h Volume" value="+5M" />
              <Stats label="Listed Cryptocurrencies" value="+5M" />
            </HStack>
            <Button
              fontWeight="400"
              color="muted"
              _hover={{ bg: 'transparent' }}
              maxW="300px"
              w="100%"
              justifyContent="space-between"
              size="lg"
              variant="outline"
            >
              Learn more <BsArrowRight />
            </Button>
          </Box>
        </Flex>
        <Box as="section" mt="100px">
          <Heading as="h2" fontSize="40px" mb="45px" w="50%">
            Explore Our Extensive Product Suite
          </Heading>
          <Flex gap="35px">
            <Box flex="0.5">
              <OurProductCard
                image={Spot}
                title="Spot"
                message="Over 30 coins available. Buy and sell quickly with automatic calculation of average cost and PnL."
              />
              <OurProductCard
                image={p2p}
                title="P2P"
                message="Over 30 coins available. Buy and sell quickly with automatic calculation of average cost and PnL."
              />
              <OurProductCard
                image={features}
                title="Features"
                message="Over 30 coins available. Buy and sell quickly with automatic calculation of average cost and PnL."
              />
            </Box>
            <Box flex="0.5">
              <OurProductCard
                image={nft}
                title="NFT"
                message="Over 30 coins available. Buy and sell quickly with automatic calculation of average cost and PnL."
              />
              <OurProductCard
                image={copy}
                title="Copy Trading"
                message="Over 30 coins available. Buy and sell quickly with automatic calculation of average cost and PnL."
              />

              <OurProductCard
                image={crypto}
                title="Crypto Lending"
                message="Over 30 coins available. Buy and sell quickly with automatic calculation of average cost and PnL."
              />
            </Box>
          </Flex>
        </Box>
        <Flex as="section" mt="100px" alignItems="center">
          <Box flex="1">
            <Heading as="h2" fontSize="40px" mb="20px">
              Build your crypto portfolio
            </Heading>
            <Flex pt="70px" gap="35px">
              <Image w="90px" h="80px" src={Certificate} />
              <Box>
                <Heading as="h4" fontSize="20px">
                  Verify your Identity
                </Heading>
                <Text color="muted" mt="15px" fontSize="18px">
                  Over 30 coins available. Buy and sell quickly with automatic
                  calculation of average cost and PnL.
                </Text>
              </Box>
            </Flex>
            <Flex pt="70px" gap="35px">
              <Image w="90px" h="80px" src={account} />
              <Box>
                <Heading as="h4" fontSize="20px">
                  Fund your account
                </Heading>
                <Text color="muted" mt="15px" fontSize="18px">
                  Over 30 coins available. Buy and sell quickly with automatic
                  calculation of average cost and PnL.
                </Text>
              </Box>
            </Flex>
            <Flex pt="70px" gap="35px">
              <Image w="90px" h="80px" src={trade} />
              <Box>
                <Heading as="h4" fontSize="20px">
                  Start trading
                </Heading>
                <Text color="muted" mt="15px" fontSize="18px">
                  Over 30 coins available. Buy and sell quickly with automatic
                  calculation of average cost and PnL.
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box flex="1">
            <Image src={Mobiles} />
          </Box>
        </Flex>
        <Box as="section" mt="100px" alignItems="center">
          <Heading as="h2">Our Partners</Heading>
          <Flex gap="20px" mt="45px">
            {[1, 2, 3].map(item => (
              <Card
                key={item}
                rounded="7px"
                border="0.5px solid #D9C0EE"
                boxShadow="2px 2px 4px rgba(99, 66, 128, 0.1)"
                w="391px"
                h="223px"
                alignItems="center"
                justifyContent="center"
              >
                <Image w="90px" mx="auto" src={Sponsor} />
                <Text mt="24px" fontWeight="700">
                  MoonPay
                </Text>
              </Card>
            ))}
          </Flex>
        </Box>
        <Flex as="section" mt="100px" alignItems="center">
          <Box flex="1">
            <Heading as="h2" fontSize="40px" mb="40px">
              Trade. Anytime. Anywhere.
            </Heading>
            <Text color="muted" mb="45px" fontSize="18px">
              Over 30 coins available. Buy and sell quickly with automatic
              calculation of average cost and PnL.
            </Text>
            <Image src={MobileApp} />
          </Box>
          <Box flex="1">
            <Flex
              alignItems="center"
              mx="auto"
              flexWrap="wrap"
              maxW="316px"
              justifyContent="space-between"
            >
              <Image w="124px" src={QRCode} />
              <Text w="150px" color="muted" fontSize="18px">
                Scan to Download
                <Text fontSize="20px" color="text">
                  iOS & Android
                </Text>
              </Text>
              <StoreButton Icon={IoLogoApple} storeName=" Apple Store" />
              <StoreButton
                Icon={IoLogoGooglePlaystore}
                storeName="Google Play"
              />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </>
  )
}

function Stats({
  label,
  value,
  labelColor,
  valueColor,
  labelSize,
  valueSize,
  p,
  mt,
}: {
  label: string
  value: string
  labelColor?: string
  valueColor?: string
  labelSize?: string
  valueSize?: string
  p?: number
  mt?: number
}) {
  return (
    <Box textAlign="center" p={p || 5}>
      <Heading
        as="h6"
        color={valueColor || 'text'}
        fontSize={valueSize || '20px'}
      >
        {value}
      </Heading>
      <Text
        mt={mt || 4}
        color={labelColor || 'muted'}
        fontSize={labelSize || '18px'}
      >
        {label}
      </Text>
    </Box>
  )
}

function StoreButton({
  storeName,
  Icon,
}: {
  Icon: IconType
  storeName: string
}) {
  return (
    <Button
      as="a"
      href="#"
      w="150px"
      h="auto"
      py="6px"
      mt="35px"
      variant="outline"
      color="text"
      borderColor="muted"
    >
      <Icon />
      <Text fontSize="12px" ml="8px" color="muted">
        Download on{' '}
        <Text color="text" fontSize="14px" fontWeight="600">
          {storeName}
        </Text>
      </Text>
    </Button>
  )
}

function OurProductCard({
  title,
  message,
  image,
}: {
  title: string
  message: string
  image: string
}) {
  return (
    <Card
      rounded="7px"
      border="0.5px solid #D9C0EE"
      boxShadow="2px 2px 4px rgba(99, 66, 128, 0.1)"
      direction="row"
      p="40px 30px"
      mb="35px"
      alignItems="center"
      gap="15px"
    >
      <Image objectFit="contain" w="100px" src={image} />
      <Box flex="1">
        <Heading as="h4" fontSize="30px">
          {title}
        </Heading>
        <Text color="muted" mt="15px" fontSize="18px">
          {message}
        </Text>
      </Box>
      <BsArrowRight fontSize="24px" />
    </Card>
  )
}

function TradersCard() {
  return (
    <Card
      maxW="337px"
      bg="primary.400"
      boxShadow="0px 0px 20px rgba(147, 78, 206, 0.5)"
      borderRadius="10px 10px 0px 0px"
      color="White"
    >
      <CardHeader pt="45px">
        <Heading
          fontSize="16px"
          lineHeight="0.01em"
          borderBottom="1px solid #fff"
          textAlign="center"
        >
          <Text as="span" bg="primary.400" p="29px 8px">
            Trending Traders
          </Text>
        </Heading>
      </CardHeader>
      <CardBody>
        <Flex gap="15px" alignItems="center">
          <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
          <Heading size="xs">Segun Adebayo</Heading>
        </Flex>
        <Graph
          colors={['#ffffff']}
          data={[30, 90]}
          width="100%"
          height="90px"
          categories={['0', '89']}
        />
        <HStack mb="20px" justifyContent="space-between">
          <Stats
            label="30D IR"
            value="+5M"
            valueColor="white"
            labelColor="primary.100"
            valueSize="16px"
            labelSize="14px"
            p={2}
            mt={2}
          />
          <Stats
            label="Copiers"
            value="+5M"
            valueColor="white"
            labelColor="primary.100"
            valueSize="16px"
            labelSize="14px"
            p={2}
            mt={2}
          />
          <Stats
            label="Cumulative Earnings"
            value="+5M"
            valueColor="white"
            valueSize="16px"
            labelSize="14px"
            labelColor="primary.100"
            p={2}
            mt={2}
          />
        </HStack>
        <Heading mb="20px" size="xs">
          Copiers
        </Heading>
        <Flex gap="2" mb="2" alignItems="center" flexWrap="wrap">
          <Avatar
            size="sm"
            name="Segun Adebayo"
            src="https://bit.ly/sage-adebayo"
          />
          <Heading flex="1" size="xs">
            Segun Adebayo
          </Heading>
          <Text color="primary.100" fontSize="12px" textAlign="right">
            +100
            <Text as="span" display="block">
              Total USDT Profit
            </Text>
          </Text>
        </Flex>
        <Flex gap="2" mb="2" alignItems="center" flexWrap="wrap">
          <Avatar
            size="sm"
            name="Segun Adebayo"
            src="https://bit.ly/sage-adebayo"
          />
          <Heading flex="1" size="xs">
            Segun Adebayo
          </Heading>
          <Text color="primary.100" fontSize="12px" textAlign="right">
            +100
            <Text as="span" display="block">
              Total USDT Profit
            </Text>
          </Text>
        </Flex>
      </CardBody>
    </Card>
  )
}
