import React, { useState } from 'react'
import './market.scss'
import {
  Stack,
  Box,
  Text,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  HStack,
  Container,
  Grid,
} from '@chakra-ui/react'

import DataTable from 'components/table'

import { Search2Icon } from '@chakra-ui/icons'
import { data } from 'utils/MarketData'
import { columns } from 'utils/MarketData'
import { FavoriteIcons } from 'assets/icons'
import MarketCoinStats from 'components/marketCoinStats'

export default function Market() {
  return (
    <Container maxW="1280px" pt="30px">
      <Text as="span" color="muted" fontSize="14px">
        In the past 24 hours
      </Text>
      <Text
        as="h1"
        fontWeight="700"
        color="heading"
        fontSize="30px"
        my="0.7rem"
      >
        Market is up{' '}
        <Text as="span" color="success">
          2.29%
        </Text>
      </Text>
      <Grid
        my="25px"
        gridTemplateColumns="repeat( auto-fit, minmax(320px, 1fr))"
      >
        {[0, 1, 2, 3, 4, 5].map(item => (
          <MarketCoinStats key={item} />
        ))}
      </Grid>
      <Flex
        border="1px solid #EBEEEF"
        bg="white"
        pt="40px"
        rounded="10px"
        position="relative"
      >
        <Stack
          top="30px"
          right="20px"
          position="absolute"
          spacing={3}
          border="0px"
          borderColor="green.700"
        >
          <Flex position="relative">
            <Search2Icon left={2} top={3} position="absolute"></Search2Icon>
            <Input pl={8} variant="outline" placeholder="Btc" />
          </Flex>
        </Stack>
        <Tabs w="100%">
          <TabList>
            <Tab px="2.5rem">
              <FavoriteIcons />
              <Text pl={2} color="heading">
                Favorites
              </Text>
            </Tab>
            <Tab px="2rem">
              <Text pl={2} color="heading">
                Spot ( 10 )
              </Text>
            </Tab>
            <Tab px="2rem">
              <Text pl={2} color="heading">
                Futures ( 10 )
              </Text>
            </Tab>
            <Tab px="2rem">
              <Text pl={2} color="heading">
                New Listings
              </Text>
            </Tab>
            <Tab px="2rem">
              <Text pl={2} color="heading">
                All Currencies ( 10 )
              </Text>
            </Tab>
          </TabList>

          <TabPanels>
            {[1, 2, 3, 4, 5].map(item => (
              <TabPanel key={item}>
                {/* Filters */}
                <CommonFilters />
                <DataTable columns={columns} data={data} />
                {/* Table */}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Flex>
    </Container>
  )
}

const CommonFilters = () => {
  const [currencyFilter, setcurrencyFilter] = useState<string>('All')
  const filterTypes = [
    'All',
    'Top',
    'Stablecoin',
    'Layer 1/2',
    'Defi',
    'Web 3',
    'Marketplace',
    'NFTSHARD',
    'Emerging',
    'MetaVerse',
    'Infrastructure',
  ]
  return (
    <HStack
      my-2
      w="100%"
      border="0px"
      borderColor="green.500"
      spacing="23px"
      mb="10px"
    >
      {filterTypes.map((item, index: number) => {
        return (
          <Box
            _hover={{ bg: 'secondary' }}
            cursor="pointer"
            key={index}
            px="15px"
            borderRadius="5px"
            py="5px"
            bg={currencyFilter == item ? 'secondary' : 'ChangeFilter'}
            onClick={() => {
              setcurrencyFilter(item)
            }}
          >
            <Text
              _hover={{ color: 'secondaryText' }}
              fontSize={12}
              color={currencyFilter == item ? 'secondaryText' : 'heading'}
            >
              {item}
            </Text>
          </Box>
        )
      })}
    </HStack>
  )
}
